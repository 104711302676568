












import { mixins } from 'vue-class-component';
import { Component, Ref } from 'vue-property-decorator';
import QueryParserMixin, { QueryType } from '~/mixins/query-parser-mixin';
import appendHash from '~/utils/AppendAccessTokenHash';
import IframeHolderMixin from '~/mixins/iframe-holder';

@Component({
    components: {},
    computed: {},
    metaInfo() {
        return {
            title: "審核項目列表",
        };
    },
})


export default class Course extends mixins(QueryParserMixin, IframeHolderMixin) {
    private messageHandler: any = null;
    courseId: string = '';

    //  UI DATA
    queryDef = [
        { localVar: 'courseId', queryStr: 'courseId', type: QueryType.String },
    ];

    // iframe Course
    @Ref() iframeReviewWindow: HTMLIFrameElement;

    iframeReviewLink: string = '';

    async handleMessage(event: MessageEvent) {
        const messageType = event.data.name || event.data.type;
        switch (messageType) {
            // case 'navigate':
        }
    }

    async mounted() {
        // Add event listeners
        this.messageHandler = this.handleMessage.bind(this);
        window.addEventListener('message', this.messageHandler, false);

        // Extract courseId from the current window's URL
        const parentUrl = window.location.href;
        const courseIdMatch = parentUrl.match(/\/review\/([^?]+)/);

        // Update the class-level courseId property
        this.courseId = courseIdMatch ? courseIdMatch[1] : '';

        await this.updateIframeReviewLink()
    }
    async created() {
        this.parseQuery();
    }

    async beforeDestroy() {
        window.removeEventListener('message', this.messageHandler);
    }

    async updateIframeReviewLink() {
        const params = {
            noframe: '1',
            aa: '1',
            bg: 'transparent',
        };

        // Create the full URL with search parameters
        const url = new URL(`${process.env.VUE_APP_DOMAIN_PORTAL_TUTOR}/course/edit-course/review/${this.courseId}`);

        for (const [key, value] of Object.entries(params)) {
            if (value !== null && value !== undefined) url.searchParams.set(key, value.toString());
        }

        // Append hash with authentication tokens and other necessary data
        const hashData = {
            access_token: this.$auth.accessToken,
            refresh_token: this.$auth.refreshToken,
            refresh_token_exp: this.$auth.refreshTokenExpire.toString(),
            mid: this.$auth.memberId,
        };

        // Update iframeReviewLink with the new URL
        this.iframeReviewLink = appendHash(url.href, hashData);
    }
}
